import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import API_BASE_URL from './Api';

export default function ContactUs() {
  // State to hold form data
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    message: ''
  });

  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/addContactDetails`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      Swal.fire({
        title: 'Success!',
        text: response.data.message,
        icon: 'success',
        confirmButtonText: 'OK'
      });

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNo: '',
        message: ''
      });

      setResponse({ message: response.data.message });
    } catch (error) {
      console.error('Error submitting contact form:', error);

      Swal.fire({
        title: 'Error!',
        text: 'Failed to submit contact form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK'
      });

      setResponse({ message: 'Failed to submit contact form.' });
    }
  };

  return (
    <div>
      {/* Page Header Start */}
      <div className="container-fluid page-header py-6 my-6 mt-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">Contact Us</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
              {/* <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li> */}
              <li className="breadcrumb-item text-primary active" aria-current="page">Contact</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}

      {/* Contact Start */}
      <div className="container-xxl py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ "min-height": "450px" }}>
              <div className="position-relative h-100">
                <iframe className="position-relative w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3636.65034955968!2d73.857849!3d18.4618291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x64dafbe2c95323f7%3A0xd4def4da963b90aa!2sVtech%20Academy!5e1!3m2!1sen!2sin!4v1739963060215!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                  frameBorder="0" style={{ "min-height": "450px", border: "0" }} aria-hidden="false"
                  tabIndex="0"
                  title='map'></iframe>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <h6 className="text-primary text-uppercase mb-2">Contact Us</h6>
              <h1 className="head1 display-6 mb-4">If You Have Any Query, Please Contact Us</h1>
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control border-0 bg-light" id="firstName" placeholder="Your Name" value={formData.firstName} onChange={handleChange} />
                      <label htmlFor="firstName">First Name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input type="text" className="form-control border-0 bg-light" id="lastName" placeholder="Your last name" value={formData.lastName} onChange={handleChange} />
                      <label htmlFor="lastName">Last Name</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="email" className="form-control border-0 bg-light" id="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input type="text" className="form-control border-0 bg-light" id="phoneNo" placeholder="Phone Number" value={formData.phoneNo} onChange={handleChange} />
                      <label htmlFor="phoneNo">Phone Number</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control border-0 bg-light" placeholder="Leave a message here" id="message" style={{ height: "150px" }} value={formData.message} onChange={handleChange}></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="booknow btn btn-primary py-3 px-5" type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Contact End */}
    </div>
  );
}

